<template>
  <SlModal
    :id="id"
    :title="$t('Web.PlannedOrders.Configuration')"
    @created="onCreated"
    @on-enter="handleApply"
    @hide="onModalHide"
  >
    <div class="modal-content">
      <div class="configuration">
        <div class="configuration__checkbox">
          <SlCheckbox
            :value="filters[0]"
            :selected="1"
            :not-selected="0"
            :label="$t('PlannedOrders.Ui.chkIncludeZeroQty')"
            @change="(val) => handleCheckboxChange(val, 0)"
          />
        </div>
        <div class="configuration__checkbox">
          <SlCheckbox
            :value="filters[1]"
            :selected="1"
            :not-selected="0"
            :label="$t('PlannedOrders.Ui.chkHideFutureOrders')"
            @change="(val) => handleCheckboxChange(val, 1)"
          />
          <SlInfoButton
            v-tooltip.right="getTooltip($t('Web.PlannedOrders.HideFutureOrdersTooltip'))"
          />
        </div>
        <div class="configuration__checkbox">
          <SlCheckbox
            :value="filters[2]"
            :selected="1"
            :not-selected="0"
            :disabled="!filters[1]"
            :label="$t('PlannedOrders.Ui.chkAcceleratePurchaseOrders')"
            @change="(val) => handleCheckboxChange(val, 2)"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          :disabled="!$sl_tabEditable"
          @click="handleApply"
        >
          {{ $t('Web.Modals.BtnApply') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'ConfigurationModal',
  mixins: [modal, access],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filters: [],
      applyCallback: null,
      getTooltip
    };
  },
  methods: {
    onModalHide() {
      this.filters = [];
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    handleApply() {
      this.applyCallback && this.applyCallback(this.filters);
      this.hideModal(this.id);
    },
    handleCheckboxChange(val, index) {
      this.$set(this.filters, index, val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";

.configuration {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 10px;

  .configuration__checkbox {
    display: inline-flex;
    gap: 4px;

    &:last-child {
      padding-left: 16px;

      border-left: 1px solid $grey-30;
    }
  }
}
</style>